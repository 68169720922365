import React, {useState, useEffect} from "react";
import moment from "moment";
import {useSelector, useDispatch} from "react-redux";
import DateRangeFilter from "../../../Common/Tables/FilterBar/DateRangeFilter";
import {setFilterDateRange} from "../../../../redux/reducers/requirements";

const RequirementsDateFilter = ({needResetFilter, setFilterHandler}) => {
    const [selectedItems, setSelectedItems] = useState();
    const dispatch = useDispatch();
    const filterDateRange = useSelector((state) => state.requirements.filterDateRange);
    const initialRange = [moment().startOf("D"), moment().add(1, "y").startOf("D")];

    const reset = () => dispatch(setFilterDateRange([null, null]));

    useEffect(() => {
        const dates =
            filterDateRange.length > 0 && filterDateRange[0] ? [moment(filterDateRange[0]), moment(filterDateRange[1])] : [];
        setSelectedItems(dates);
        applyFilter(dates);
    }, [filterDateRange]);

    useEffect(() => {
        if (needResetFilter) {
            reset();
        }
    }, [needResetFilter, reset]);

    const initialRangeChange = initialRange.map((r) => r.format("yyMMdd")).join("");
    useEffect(() => {
        if (filterDateRange.length === 0) dispatch(setFilterDateRange([initialRange[0].format(), initialRange[1].format()]));
    }, [initialRangeChange]);

    const onChanged = (filterItems) => {
        const dates =
            filterItems === null || filterItems.length === 0 ? [null, null] : [filterItems[0].format(), filterItems[1].format()];
        dispatch(setFilterDateRange(dates));
    };

    function applyFilter(filterItems) {
        if (filterItems.length === 0) {
            setFilterHandler(() => true);
        } else {
            const [filterStart, filterEnd] = filterItems;
            setFilterHandler((item) => {

                if (item.Requirement.StartDateTime === null) return true;

                const itemStartDay = moment(item.Requirement.StartDateTime).startOf("D");
                const itemEndDay = moment(item.Requirement.EndDateTime).startOf("D");
                const filterStartDay = moment(filterStart).startOf("D");
                const filterEndDay = moment(filterEnd).startOf("D");

                if (itemStartDay < filterStartDay && itemEndDay < filterStartDay) return false;

                return itemStartDay <= filterEndDay;
            });
        }
    }

    const isDefaultSelection = !selectedItems || selectedItems.length === 0;

    return (
        <DateRangeFilter
            selectedItems={selectedItems}
            allItemsText="All Dates"
            onChanged={onChanged}
            isDefaultSelection={isDefaultSelection}
            search
        />
    );
};

export default RequirementsDateFilter;
