import React, {useEffect, useState} from "react";
import {List, Button, Input, Select} from "antd";
import {CloseCircleOutlined} from "@ant-design/icons";
import "./InAttendance.scss";
import update from "immutability-helper";
import {createColorTagRendered} from "../../../../helpers/Tags";
import {formatTeamMemberName} from "../../../../helpers/text";
import {useSelector} from "react-redux";
import {ApiContactType} from "../../../../constants/constants";
import {selectFilterOption} from "../../../Common/Input";

const EditRow = ({
                     item = {},
                     onUpdate,
                     onDelete,
                     setIsQuickCreateOrgVisible,
                     setIsQuickCreateContactVisible,
                     setQuickCreateTeamMemberFrom,
                     peopleAndOrganizations,
                 }) => {
    const [teamMember, setTeamMember] = useState(item.TeamMember?.Id);
    const [notes, setNotes] = useState(item.Notes);
    const onChanged = () => {
        onUpdate({
            ...item,
            Notes: notes,
        });
    };

    useEffect(() => setTeamMember(item.TeamMember?.Id), [item.TeamMember?.Id]);

    const onTeamMemberChange = (val, option) => {
        if (!val) return;

        setTeamMember(val);
        onUpdate({
            ...item,
            TeamMember: option.team,
        });
    };
    const deleteItem = async () => {
        const {Id: itemId} = item;
        onDelete(itemId);
    };

    return (
        <div className="team-item">
            <div className="title">
                <Select
                    showSearch
                    filterOption={selectFilterOption}
                    value={teamMember}
                    dropdownClassName="people-org-ddl"
                    tagRender={createColorTagRendered("orange", true)}
                    placeholder="Select Team Member"
                    style={{width: "100%"}}
                    onChange={onTeamMemberChange}
                    maxTagTextLength={30}
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            <div className="btn-quick-create">
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        setQuickCreateTeamMemberFrom(item);
                                        setIsQuickCreateContactVisible(true);
                                    }}>
                                    + Add a Contact
                                </Button>
                                &nbsp;
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        setQuickCreateTeamMemberFrom(item);
                                        setIsQuickCreateOrgVisible(true);
                                    }}>
                                    + Add an Organization
                                </Button>
                            </div>
                        </>
                    )}>
                    <Select.OptGroup label="Project Team Members">
                        {peopleAndOrganizations.map((i) => (
                            <Select.Option label={formatTeamMemberName(i)} key={i.Id} value={i.Id} team={i}
                                           contact={i.ContactId}>
                                {formatTeamMemberName(i)}
                            </Select.Option>
                        ))}
                    </Select.OptGroup>
                </Select>

                {/* <Input
          value={name}
          onChange={({ target: { value } }) => setName(value)}
          onPressEnter={onChanged}
          onBlur={onChanged}></Input> */}
            </div>
            <div className="notes">
                <Input
                    value={notes}
                    disabled={!item?.Id}
                    onChange={({target: {value}}) => setNotes(value)}
                    onPressEnter={onChanged}
                    onBlur={onChanged}></Input>
            </div>
            <div className="actions">
                <Button onClick={deleteItem} type="link" size="small">
                    <CloseCircleOutlined className="delete"/>
                </Button>
            </div>
        </div>
    );
};

function sortTeamMembers(members = []) {
    return members.sort((tmA, tmB) => {
        if (tmA.TeamMember.Contact === null) return 1;

        const valueTmA =
            tmA.TeamMember.Contact?.Type === ApiContactType.Person ? tmA.TeamMember.Contact?.LastName : tmA.TeamMember.Contact?.Company;
        const valueTmb =
            tmB.TeamMember.Contact?.Type === ApiContactType.Person ? tmB.TeamMember.Contact?.LastName : tmB.TeamMember.Contact?.Company;
        return valueTmA > valueTmb ? 1 : -1;
    });
}

const InAttendance = ({
                          value = [],
                          onChange,
                          onChanged,
                          projectId,
                          setIsQuickCreateContactVisible,
                          setIsQuickCreateOrgVisible,
                          setQuickCreateTeamMemberFrom,
                          disabled,
                      }) => {
    const [showNewRow, setShowNewRow] = useState(false);

    const allTeamMembers = useSelector((state) => state.team.project[projectId]) || [];
    const peopleAndOrganizations = allTeamMembers.filter(
        (t) => t.Contact === null || t.Contact?.Type === ApiContactType.Person || t.Contact?.Type === ApiContactType.Organization
    );

    const inAttendanceGroup = value.find((g) => g.Name === "In Attendance") || {
        Name: "In Attendance",
        TeamMembers: [],
    };
    const items = inAttendanceGroup ? sortTeamMembers([...inAttendanceGroup.TeamMembers]) : [];
    const memberRows = showNewRow ? [...items, {}] : items;

    const onUpdate = (item) => {
        if (item.Id) {
            const target = items.find((i) => i.Id === item.Id);
            const updatedItem = update(target, {TeamMember: {$set: item.TeamMember}, Notes: {$set: item.Notes}});
            onChange([
                {
                    ...inAttendanceGroup,
                    TeamMembers: inAttendanceGroup.TeamMembers.map((tm) => (tm.Id === item.Id ? updatedItem : tm)),
                },
            ]);
            onChanged();
        } else {
            setShowNewRow(false);
            onChange([
                {
                    ...inAttendanceGroup,
                    TeamMembers: [...(inAttendanceGroup?.TeamMembers || []), item],
                },
            ]);

            onChanged(true);
        }
    };

    const addItem = () => {
        setShowNewRow(true);
    };

    const onDelete = (itemId) => {
        if (!itemId) setShowNewRow(false);
        else {
            onChange([
                {
                    ...inAttendanceGroup,
                    TeamMembers: inAttendanceGroup.TeamMembers.filter((tm) => tm.Id !== itemId),
                },
            ]);
            onChanged();
        }
    };

    return (
        <List
            header={
                <div className="team-item">
                    <div className="title">Team Member</div>
                    <div className="notes">Details</div>
                    <div className="actions">
                        <Button type="primary" size="small" disabled={disabled} onClick={addItem}>
                            Add a Team Member
                        </Button>
                    </div>
                </div>
            }
            size="small"
            dataSource={memberRows}
            renderItem={(item, index) => (
                <List.Item>
                    <EditRow
                        key={`${item.Id}-${index}`}
                        item={item}
                        onDelete={onDelete}
                        onUpdate={onUpdate}
                        peopleAndOrganizations={peopleAndOrganizations}
                        setQuickCreateTeamMemberFrom={setQuickCreateTeamMemberFrom}
                        setIsQuickCreateContactVisible={setIsQuickCreateContactVisible}
                        setIsQuickCreateOrgVisible={setIsQuickCreateOrgVisible}
                    />
                </List.Item>
            )}></List>
    );
};

export default InAttendance;
