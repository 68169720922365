import api from "../api";
import {setCurrentUser} from "../redux/reducers/auth";
import store from "../redux/store";
import {loadTimeZoneMappings, loadTimeZones} from "./infrastructure";
import {loadPortfolios} from "./projects";

const {dispatch} = store;
export const login = (userName, password, rememberMe = false) =>
    api.post("/api/Account/SignIn", {RememberMe: rememberMe, Login: userName, Password: password});

export const sendResetPasswordRequest = (email) =>
    api.post(`api/Account/ForgotPassword?emailAddressToSendResetPasswordLink=${encodeURIComponent(email)}`, {});

export const resetPassword = (resetForgottenPasswordLink, newPassword, confirmPassword) =>
    api.post('api/Account/SetUpNewPasswordByResetPasswordLink', {
        ResetForgottenPasswordLink: resetForgottenPasswordLink,
        NewPassword: newPassword,
        ConfirmPassword: confirmPassword
    });

export const whoAmI = () => whoAmILight()
    .then(() => Promise.all([
        loadPortfolios(),
        loadTimeZones(),
        loadTimeZoneMappings(),
    ]));

export const whoAmILight = () => api.get("/api/Account/WhoAmI")
    .then(whoAmIResponse => {
        dispatch(setCurrentUser(whoAmIResponse));
    });

export const validateAccountBeforeSignUp = (payload) =>
    api.post("/api/Account/ValidateAccountBeforeSignUp", payload);

export const completeQuickSignUp = (payload) =>
    api.post("/api/Account/CompleteQuickSignUp", payload);

export const updateUserSettings = (payload) =>
    api.post("/api/Account/UpdateSettings", payload);

export const logout = () =>
    api.post("/api/Account/Logout", {});
