import React, {useCallback, useEffect, useRef, useState} from "react";
import _ from "lodash";
import {Checkbox, Collapse, Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {setSelected} from "../../../redux/reducers/mainTable";
import {useLocation} from "react-router-dom";
import {useVT} from "virtualizedtableforantd4";
import useWindowSize from "../../../hooks/useWindowSize";

const GroupedTables = ({
                           columns,
                           dataSource,
                           onRow,
                           getGroups,
                           loading,
                           footer,
                           enableInfinityScrolling,
                           defaultCollapseActiveKey = [],
                       }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedAllRowKeys, setSelectedAllRowKeys] = useState([]);
    const [rerenderPanelCount, setRerenderPanelCount] = useState(0);
    const dispatch = useDispatch();
    const location = useLocation();

    const itemToEdit = useSelector((state) => state.detailsPanel.item);
    const groups = getGroups();
    const allKeys = dataSource.map((i) => i.Id);

    const getSelectedItems = useCallback(
        () => dataSource.filter((i) => selectedRowKeys.includes(i.Id)),
        [dataSource, selectedRowKeys]
    );

    useEffect(() => {
        dispatch(setSelected([]));
    }, [location, dispatch]);

    useEffect(() => {
        switch (selectedRowKeys.length) {
            case 0:
                setSelectedAllRowKeys([]);
                break;
            case allKeys.length:
                setSelectedAllRowKeys(["first", "second"]);

                break;
            default:
                setSelectedAllRowKeys(["first"]);

                break;
        }
        dispatch(setSelected(getSelectedItems()));
    }, [selectedRowKeys.length, allKeys.length, selectedRowKeys, getSelectedItems, dispatch]);

    function onGroupChange(checked, keys) {
        const withoutGroup = _.without(selectedRowKeys, ...keys);
        setSelectedRowKeys(checked ? [...withoutGroup, ...keys] : withoutGroup);
    }

    function isGroupChecked(keys) {
        const withoutGroup = _.without(selectedRowKeys, ...keys);
        return withoutGroup.length + keys.length === selectedRowKeys.length && keys.length > 0;
    }

    function isGroupIndeterminate(keys) {
        const withoutGroup = _.without(selectedRowKeys, ...keys);
        return withoutGroup.length !== selectedRowKeys.length && withoutGroup.length + keys.length !== selectedRowKeys.length;
    }

    const rowClassName = (record) => (record && itemToEdit && record.Id === itemToEdit.Id ? "row-selected-for-edit" : "");

    return (
        <>
            <Table
                columns={columns}
                dataSource={[
                    {Id: "first", Contact: {}},
                    {Id: "second", Contact: {}},
                ]}
                pagination={false}
                rowKey="Id"
                loading={loading}
                className="group-table-header"
                size="small"
                rowSelection={{
                    selectedRowKeys: selectedAllRowKeys,
                    type: "checkbox",
                    onChange: (selected) => {
                        setSelectedRowKeys(selected.length > 0 ? allKeys : []);
                    },
                }}
            />
            {groups.length > 0 && (
                <Collapse
                    defaultActiveKey={defaultCollapseActiveKey}
                    className="group-table-collapse"
                    onChange={() => {
                        setRerenderPanelCount(rerenderPanelCount + 1);
                    }}>
                    {groups.map((item, index) => (
                        <Collapse.Panel
                            key={`${item.name || item.group}`}
                            extra={item.rightText &&
                                <span className={item.rightTextClass || ""}>{item.rightText}</span>}
                            header={
                                <>
                                    <GroupHeader
                                        level={item.level}
                                        groupText={item.group}
                                        checked={isGroupChecked(item.keys)}
                                        indeterminate={isGroupIndeterminate(item.keys)}
                                        items={item.items}
                                        onChange={(e) => onGroupChange(e.target.checked, item.keys)}
                                    />
                                </>
                            }>
                            <CollapsedTable
                                tableId={index}
                                rerenderPanelCount={rerenderPanelCount}
                                loading={loading}
                                columns={columns}
                                dataSource={item.items}
                                enableInfinityScrolling={enableInfinityScrolling}
                                rowClassName={rowClassName}
                                setSelectedRowKeys={setSelectedRowKeys}
                                selectedRowKeys={selectedRowKeys}
                                onRow={onRow}
                            />
                        </Collapse.Panel>
                    ))}
                </Collapse>
            )}
            {dataSource?.length > 1 && footer && (
                <Table
                    hidden={!(dataSource?.length > 1) || !footer}
                    showHeader={false}
                    pagination={false}
                    rowKey="Id"
                    columns={[...columns.map((c) => ({...c, render: () => null}))]}
                    dataSource={dataSource}
                    rowSelection={{
                        type: "checkbox",
                    }}
                    className="group-table-header"
                    size="small"
                    summary={dataSource?.length > 1 ? footer : null}
                />
            )}
        </>
    );
};

const getLevelClassName = (level = 0) => (level > 0 ? `padding_${level} nowrap` : "nowrap");

const GroupHeader = ({groupText, items, level, indeterminate, checked, onChange}) => {
    return (
        <>
            <Checkbox
                onClick={(e) => e.stopPropagation()}
                checked={checked}
                indeterminate={indeterminate}
                onChange={onChange}></Checkbox>{" "}
            <span className={getLevelClassName(level)}>
        {groupText}({items.length})
      </span>
        </>
    );
};

const CollapsedTable = ({
                            loading,
                            columns,
                            dataSource,
                            enableInfinityScrolling,
                            rowClassName,
                            selectedRowKeys,
                            setSelectedRowKeys,
                            onRow,
                            rerenderPanelCount,
                            tableId,
                        }) => {
    const size = useWindowSize();
    const tableRef = useRef();
    const tableHeight = (size?.height || 800) - (tableRef.current?.offsetTop || 0) - 40;
    const [vt] = useVT(
        () => ({
            id: tableId,
            scroll: {y: tableHeight},
        }),
        [tableHeight],
        rerenderPanelCount
    );
    if (rerenderPanelCount > 0) {
    }
    return (
        <Table
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            showHeader={false}
            ref={tableRef}
            className="common-table"
            rowKey="Id"
            size="small"
            scroll={{y: tableHeight}}
            components={enableInfinityScrolling ? vt : null}
            rowClassName={rowClassName}
            rowSelection={{
                selectedRowKeys,
                type: "checkbox",
                onChange: (selected) => setSelectedRowKeys(selected),
            }}
            onRow={onRow}
        />
    );
};

export default GroupedTables;
