import React from "react";
import {message} from "antd";
import BatchDelete from "../../Common/Modal/BatchDelete/BatchDelete";
import {loadTeam, batchDelete} from "../../../services/team";
import {useDispatch, useSelector} from "react-redux";
import {setSelected} from "../../../redux/reducers/mainTable";

const getTitle = (selected, project) => {
    if (selected.length === 1) {
        return `Are you sure you want to remove this team member from ${project?.Name}?`;
    } else {
        return `Are you sure you want to remove these team members from ${project?.Name}?`;
    }
};

const showFinishMessage = (selected) => {
    if (selected.length === 1) {
        message.success(`Team member was removed.`);
    } else {
        message.success(`${selected.length} team members were removed.`);
    }
};

const content = (
    <>
        They will be removed from anywhere they are mentioned in this project. Their contact information will not be
        deleted. <br/>
        <br/>
        You cannot undo this action.
    </>
);
const DeleteTeamMember = () => {
    const dispatch = useDispatch();
    const activeProject = useSelector((state) => state.projects.activeProject);
    const selected = useSelector((store) => store.mainTable.selected) || [];
    return (
        <BatchDelete
            selected={selected}
            buttonText="Remove"
            okText="Remove"
            onFinish={() => {
                dispatch(setSelected([]));
                return loadTeam(activeProject.Id);
            }}
            content={content}
            title={getTitle(selected, activeProject)}
            batchRemove={() => batchDelete(selected)}
            onFinishMessage={() => showFinishMessage(selected)}></BatchDelete>
    );
};
export default DeleteTeamMember;
