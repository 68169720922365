import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import {Collapse, DatePicker, Divider, Form, message} from "antd";
import {FolderOpenFilled, InfoCircleOutlined} from "@ant-design/icons";
import {ApiProjectType, NewRecordState, TagType} from "../../../constants";
import {continueEdit} from "../../../redux/reducers/detailsPanel";
import {InputWithFormItemWithRef, SelectWithFormItem, TextAreaWithFormItem} from "../../Common/Input";
import {useDispatch, useSelector} from "react-redux";
import {createProject, getProject, refreshProjects, updateProject} from "../../../services/projects";
import {mapTagToOption, mapTextToOption} from "../../../helpers/Tags";
import ProjectManagers, {QuickCreateProjectManager} from "./ProjectManagers/ProjectManagers";
import {getAccessByProject} from "../../../services/settings";
import {errorFromHttpResponse, isRequestCanceled} from "../../../helpers/error";
import ProductionBooksByProject from "./ProductionBooksByProject";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";
import ColorPicker, {defaultColor} from "../ColorPicker";
import {loadProductionBooks} from "../../../services/productionBooks";
import usePermissions from "../../../hooks/usePermissions";
import TagsSelect from "../../Common/Selects/TagsSelect";
import AuditFormItem from "../../Common/AuditFormItem";

const InformationHeader = (
    <>
        <InfoCircleOutlined/> Information
    </>
);
const ProductionBooksHeader = (
    <>
        <FolderOpenFilled/> Production Books
    </>
);

const projectStates = [
    {
        value: ApiProjectType.Active,
        label: "Active",
    },
    {
        value: ApiProjectType.Inactive,
        label: "Draft",
    },
];
const prepareFormData = (project) => {
    const formData = {
        ...project,
        Dates: [project.ProjectDate, project.ProjectEndDate],
        CategoryTags: (project.CategoryTags || []).map((t) => t.AccountTagId.toString()),
    };
    return formData;
};

function getDate(date) {
    return date ? moment(date) : null;
}

const ProjectDetailsPanel = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const firstInputRef = useRef();
    const nextInputRef = useRef();
    const dateTimeFormats = useDateTimeFormat();
    const {canManageProjects} = usePermissions();
    const [currentUserState, setCurrentUserState] = useState(NewRecordState.Empty);
    const [admins, setAdmins] = useState([]);
    const [owners, setOwners] = useState([]);

    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const categories = useSelector((state) => state.tags.categories).map(mapTagToOption);
    const timeZones = useSelector((state) => state.infrastructure.timeZones).map(mapTextToOption);
    const itemToEdit = useSelector((state) => state.detailsPanel.item);
    const [isQuickCreateContactVisible, setIsQuickCreateContactVisible] = useState(false);

    if (currentUserState === NewRecordState.RequiredFieldsSubmitted) {
        setTimeout(() => nextInputRef.current?.focus(), 100);
    }

    const setFields = () => {
        setCurrentUserState(NewRecordState.ReadyForEdit);
        const formData = prepareFormData(itemToEdit);
        form.setFieldsValue(formData);
        setAssistants();
    };

    useEffect(() => {
        if (!itemToEdit) {
            setTimeout(() => firstInputRef.current.focus(), 200);
            return;
        }

        setFields();
    }, [itemToEdit, form]);

    const itemToEditId = itemToEdit?.Id;

    const setAssistants = () =>
        getAccessByProject(activePortfolio.Id, itemToEditId).then((response) => {
            setAdmins(response.AdminMembers);
            setOwners(response.OwnerMembers);
            form.setFieldsValue({
                Assistants: response.AssistantMembers.map((p) => ({
                    Id: p.Id,
                    ContactId: p.CreatedFromContactId?.toString(),
                    MemberAccountId: p.MemberAccountId,
                    EmailAddresses: [p.EmailAddress],
                })),
            });
        });

    function onFinish(finishedForm) {
        // if (!projects) return;
        if (currentUserState === NewRecordState.Empty) {
            prepareAndCreateProject(finishedForm);
        }

        if (currentUserState === NewRecordState.ReadyForEdit) {
            prepareAndUpdateProject(finishedForm);
        }
    }

    function prepareAndCreateProject(finishedForm) {
        const payload = {
            Name: finishedForm.Name,
            State: ApiProjectType.Active,
            UpdaterAccountId: activePortfolio.Id,
            TimeZoneId: activePortfolio.TimeZoneId,
            Color: defaultColor,
            Assistants: [],
            ProjectDate: null,
            ProjectEndDate: null,
        };
        createProject(payload)
            .then((createProjectResponse) => {
                dispatch(continueEdit({item: {...payload, ...createProjectResponse}}));
                return createProjectResponse;
            })
            .then((createProjectResponse) =>
                Promise.all([
                    refreshProjects(),
                    getProject(createProjectResponse.Id).then((project) => {
                        dispatch(continueEdit({item: project}));
                        setCurrentUserState(NewRecordState.ReadyForEdit);
                        message.success(`${project.Name} was created`);
                    }),
                    loadProductionBooks(activePortfolio.Id),
                ])
            )
            .catch((err) => {
                console.error(err);
                setCurrentUserState(NewRecordState.Empty);
            });

        setCurrentUserState(NewRecordState.RequiredFieldsSubmitted);
    }

    function prepareAndUpdateProject(finishedForm) {
        const [ProjectDate, ProjectEndDate] = finishedForm.Dates || [];
        const updatePayload = {
            ...itemToEdit,
            ...finishedForm,
            Id: itemToEdit.Id,

            ProjectDate,
            ProjectEndDate,
            UpdaterAccountId: activePortfolio.Id,
            CategoryTags: finishedForm.CategoryTags.map((t) => parseInt(t, 10)),
            Assistants: (finishedForm.Assistants || []).map((a) => ({...a, ContactId: parseInt(a.ContactId, 10)})),
        };

        updateProject(updatePayload)
            .then(() => getProject(itemToEdit.Id).then((project) => dispatch(continueEdit({item: project}))))
            .then(() =>
                Promise.all([refreshProjects(), getProject(itemToEdit.Id).then((project) => dispatch(continueEdit({item: project})))])
            )
            .catch((err) => {
                if (!isRequestCanceled(err)) {
                    const errorText = errorFromHttpResponse(err);
                    message.error(errorText);
                    setFields();
                }
            });
    }

    const onPmCreated = (newPm) => {
        const assistants = form.getFieldValue("Assistants") || [];
        form.setFieldsValue({
            Assistants: [...assistants, newPm],
        });
        save();
    };

    const isReadOny = itemToEdit?.State === ApiProjectType.Archive;
    const isOptionalFieldDisabled = currentUserState !== NewRecordState.ReadyForEdit || isReadOny;

    const save = async (a) => {
        try {
            const values = await form.validateFields();
            onFinish(values);
        } catch (err) {
            console.error(err);
        }
    };

    const projectStatesForCurrentProject =
        itemToEdit?.State === ApiProjectType.Archive
            ? [
                ...projectStates,
                {
                    value: ApiProjectType.Archive,
                    label: "Archive",
                },
            ]
            : projectStates;

    return (
        <div className="side-panel with-collapse">
            <Collapse defaultActiveKey={["Information", "ProductionBooks"]}>
                <Collapse.Panel key="Information" header={InformationHeader}>
                    <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                        <Divider orientation="left" className="divider-first">
                            Basic Info
                        </Divider>
                        <InputWithFormItemWithRef
                            ref={firstInputRef}
                            name="Name"
                            label="Project Name"
                            hasFeedback
                            rules={[{required: true, message: "Name"}]}
                            onChanged={save}
                            disabled={isReadOny}
                            placeholder="Enter a name to create new project"
                        />
                        <InputWithFormItemWithRef
                            name="ShortName"
                            label="Short Name"
                            ref={nextInputRef}
                            onChanged={save}
                            disabled={isOptionalFieldDisabled}
                            placeholder="Enter a project short name"
                        />
                        <Form.Item label="Color" name="Color">
                            <ColorPicker disabled={isOptionalFieldDisabled} save={save}></ColorPicker>
                        </Form.Item>
                        <Form.Item
                            name="Dates"
                            label="Date"
                            getValueProps={(dates) => {
                                const [start, end] = dates || [];
                                return {
                                    value: [getDate(start), getDate(end)],
                                };
                            }}>
                            <DatePicker.RangePicker
                                format={dateTimeFormats.date}
                                disabled={isOptionalFieldDisabled}
                                onOpenChange={(open) => {
                                    if (!open) save();
                                }}
                                onCalendarChange={(dates) => {
                                    const [start, end] = dates || [];
                                    form.setFieldsValue({
                                        Dates: [start?.format(), end?.format()],
                                    });

                                    if (dates === null) save();
                                }}></DatePicker.RangePicker>
                        </Form.Item>
                        <Form.Item label="Project Managers" name="Assistants">
                            <ProjectManagers
                                admins={admins}
                                owners={owners}
                                disabled={isOptionalFieldDisabled || !canManageProjects}
                                setIsQuickCreateContactVisible={setIsQuickCreateContactVisible}
                                onChanged={save}></ProjectManagers>
                        </Form.Item>

                        <Divider orientation="left">Additional Details</Divider>
                        <TagsSelect
                            tagType={TagType.Category}
                            disabled={isOptionalFieldDisabled}
                            label="Categories"
                            name="CategoryTags"
                            placeholder="Select categories"
                            options={categories}
                            save={save}
                            form={form}
                        />

                        <TextAreaWithFormItem
                            label="Internal Details"
                            name="Notes"
                            onChanged={save}
                            autoSize={{minRows: 2}}
                            disabled={isOptionalFieldDisabled}
                            placeholder="Enter details"></TextAreaWithFormItem>

                        <SelectWithFormItem
                            disabled={isOptionalFieldDisabled}
                            label="Time Zone"
                            name="TimeZoneId"
                            style={{width: "100%"}}
                            options={timeZones}
                            onChanged={save}
                            showSearch={true}
                        />
                        <SelectWithFormItem
                            disabled={isOptionalFieldDisabled}
                            label="State"
                            name="State"
                            style={{width: "100%"}}
                            options={projectStatesForCurrentProject}
                            onChanged={save}
                        />
                        <AuditFormItem item={itemToEdit} label="Created" timeProp="CreatedAt" userProp="CreatedBy"/>

                        <AuditFormItem item={itemToEdit} label="Last Updated" timeProp="UpdatedAt"
                                       userProp="UpdatedBy"/>
                    </Form>
                </Collapse.Panel>
                {!isReadOny && (
                    <Collapse.Panel key="ProductionBooks" header={ProductionBooksHeader}
                                    className="contact-details-panel-projects">
                        <ProductionBooksByProject project={itemToEdit}></ProductionBooksByProject>
                    </Collapse.Panel>
                )}
            </Collapse>
            <QuickCreateProjectManager
                isQuickCreateContactVisible={isQuickCreateContactVisible}
                setIsQuickCreateContactVisible={setIsQuickCreateContactVisible}
                onPmCreated={onPmCreated}></QuickCreateProjectManager>
        </div>
    );
};

export default ProjectDetailsPanel;
