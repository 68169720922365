import React from 'react';
import {ButtonComponent} from "@syncfusion/ej2-react-buttons";
import {CopyFilled} from "@ant-design/icons";
import './QuickInfoHeaderTemplate.scss';

const getHeaderStyles = (data) => {
    return {background: data.ProjectColor};
};

const buttonClickActions = (e,
                            props,
                            handleEditClick,
                            handleCloneClick,
                            handleDeleteClick,
                            scheduleObj) => {
    if (e.currentTarget.id === 'edit') {
        handleEditClick(props);
    } else if (e.currentTarget.id === 'clone') {
        handleCloneClick(props);
    } else if (e.currentTarget.id === 'delete') {
        handleDeleteClick({data: props});
    } else {
        console.error('Unknown button clicked');
    }
    scheduleObj.current.closeQuickInfoPopup();
};

const QuickInfoHeaderTemplate = (props) => {
    const {handleEditClick, handleCloneClick, handleDeleteClick, scheduleObj} = props;

    return (
        <div className="quick-info-header">
            <div className="e-popup-header" style={getHeaderStyles(props)}>
                <div className="e-header-icon-wrapper">
                    <ButtonComponent
                        id="edit"
                        className="e-edit e-control e-btn e-lib e-flat e-round e-small e-icon-btn"
                        title="Edit"
                        onClick={(e) => buttonClickActions(e, props, handleEditClick, handleCloneClick, handleDeleteClick, scheduleObj)}
                    >
                        <span className="e-btn-icon e-icons e-edit-icon"></span>
                    </ButtonComponent>

                    <ButtonComponent
                        id="clone"
                        className="e-clone e-control e-btn e-lib e-flat e-round e-small e-icon-btn"
                        title="Clone"
                        onClick={(e) => buttonClickActions(e, props, handleEditClick, handleCloneClick, handleDeleteClick, scheduleObj)}
                    >
                        <CopyFilled className="btn-table-actions"/>
                    </ButtonComponent>
                    <ButtonComponent
                        id="delete"
                        className="e-delete e-control e-btn e-lib e-flat e-round e-small e-icon-btn"
                        title="Delete"
                        onClick={(e) => buttonClickActions(e, props, handleEditClick, handleCloneClick, handleDeleteClick, scheduleObj)}
                    >
                        <span className="e-btn-icon e-icons e-delete-icon"></span>
                    </ButtonComponent>
                    <ButtonComponent
                        id="close"
                        className="e-close e-control e-btn e-lib e-flat e-round e-small e-icon-btn"
                        title="Close"
                        onClick={(e) => buttonClickActions(e, props, handleEditClick, handleCloneClick, handleDeleteClick, scheduleObj)}
                    >
                        <span className="e-btn-icon e-icons e-close-icon"></span>
                    </ButtonComponent>
                </div>
                <div className="e-subject-wrap">
                    <div className="e-subject e-text-ellipsis">{props.Subject}</div>
                </div>
            </div>
        </div>
    );
};

export default QuickInfoHeaderTemplate;