import React, {useState} from "react";
import {useDispatch} from "react-redux";
import moment from "moment";
import {DatePicker, Menu, Dropdown, Button} from "antd";
import "./DateRangeSelector.scss";
import useDateTimeFormat from "../../../../hooks/useDateTimeFormat";
import {DownOutlined} from "@ant-design/icons";
import {getProjectEventDateRangeWithDefaults} from "../../../../services/timeline";
import {setTimeLineLoading} from "../../../../redux/reducers/timeline";

const predefinedRanges = {
    "Next 7 Days": [moment(), moment().add(7, "day")],
    "Next 30 Days": [moment(), moment().add(30, "day")],
    "Next 12 Months": [moment(), moment().add(12, "month")],
    "All Dates": [null, null],
};

const formatDate = (start = null, end = null, formats) => {
    const mStart = moment(start);
    const mEnd = moment(end);

    const formatWithYear = formats.dateLongFormat;

    if (!mStart.isValid() && !mEnd.isValid()) {
        return;
    }

    if (mStart.isValid() && !mEnd.isValid()) {
        return mStart.format(formatWithYear);
    }

    const daysDiff = mEnd.diff(mStart, "days");

    return daysDiff === 0 ? (mStart.format(formatWithYear)) : (`${mStart.format(formatWithYear)} – ${mEnd.format(formatWithYear)}`);
};

const isValidDateRange = (dates, allowNull = false) => {
    if (allowNull && dates[0] === null && dates[1] === null) {
        return true;
    }
    return dates && dates[0] && dates[1] && !moment(dates[0]).isAfter(dates[1]) && !moment(dates[1]).isBefore(dates[0]);
};

const handleCalendarChange = (dates, onChanged, isAllDatesClicked = false, dispatch) => {
    if (!isAllDatesClicked && dates[0] === null && dates[1] === null) {
        return;
    }

    if (!isValidDateRange(dates, isAllDatesClicked)) {
        return;
    }

    if (isAllDatesClicked) {
        getProjectEventDateRangeWithDefaults().then((range) => {

            dispatch(setTimeLineLoading(false));

            if (!range || !range.MinStartDate || !range.MaxEndDate) {
                dates[0] = null;
                dates[1] = null;
                return;
            }

            const updatedDates = [moment(range.MinStartDate), moment(range.MaxEndDate)];
            dates[0] = moment(range.MinStartDate);
            dates[1] = moment(range.MaxEndDate);

            onChanged(updatedDates);

        });
    } else {
        onChanged(dates);
    }
};

const DateRange = ({onChanged, dateTimeFormats, selectedItems, dispatch}) => (
    <Menu selectable={false} style={{width: 560}}>
        <Menu.Item key="DateRange" onClick={() => {
        }} style={{height: 341}} className="date-range-menu-item">
            <div className="date-range-filter">
                <div>
                    <DatePicker.RangePicker
                        open
                        value={selectedItems}
                        format={dateTimeFormats.date}
                        bordered={false}
                        dropdownClassName="date-range-dates"
                        getPopupContainer={(trigger) => trigger.parentNode.parentNode.childNodes[1]}
                        ranges={{
                            "Next 7 Days": predefinedRanges["Next 7 Days"],
                            "Next 30 Days": predefinedRanges["Next 30 Days"],
                            "Next 12 Months": predefinedRanges["Next 12 Months"],
                            "All Dates": predefinedRanges["All Dates"],
                        }}
                        onCalendarChange={(dates) => {
                            const isAllDates = dates === predefinedRanges["All Dates"];
                            handleCalendarChange(dates, onChanged, isAllDates, dispatch);
                        }} allowClear={false}
                    />
                </div>
                <div className="date-range-holder"></div>
            </div>
        </Menu.Item>
    </Menu>
);

const DateRangeSelector = ({
                               items = [], selectedItems = [], search, onDateRangeChange, allItemsText
                           }) => {
    const dateTimeFormats = useDateTimeFormat();
    const dispatch = useDispatch();

    let filterText = "";

    if (selectedItems === null || selectedItems.length === 0) {
        filterText = allItemsText;
    } else {
        const [filterStart, filterEnd] = selectedItems;

        filterText = formatDate(filterStart, filterEnd, dateTimeFormats);
    }

    function onFilterChanged(filterItems) {
        if (filterItems && filterItems.length === 2) {
            onDateRangeChange(filterItems[0], filterItems[1]);
        }
    }

    return (
        <Dropdown
            trigger="click"
            overlay={<DateRange
                items={items}
                dateTimeFormats={dateTimeFormats}
                selectedItems={selectedItems}
                onChanged={onFilterChanged}
                search={search}
                dispatch={dispatch}
            />}
            className="date-range-selector"
        >
            <Button type="link" className="btn-multiple-items-filter">
                <span className="filter-text">{filterText}</span>
                <DownOutlined className="filter-text-down"/>
            </Button>
        </Dropdown>
    );
};

export default DateRangeSelector;