import api from "../api";
import store from "../redux/store";
import moment from "moment";
import {setBookings, setEvents, setTimeLineLoading} from "../redux/reducers/timeline";
import {ApiTimelineItemType} from "../constants";
import {isRequestCanceled} from "../helpers/error";
import {loadTags} from "./tags";
import {setSelected} from "../redux/reducers/mainTable";
import {loadTeam} from "./team";
import {sanitizeHTML} from "../helpers/html";

const {dispatch} = store;

const timelineDefaultFilter = {
    ProjectIds: [],
    IsInfinityScroll: false,
    ReloadGrid: false,
    IncludeTeamMembers: true,
    CategoryTags: [],
    DepartmentTags: [],
    TeamMemberContactIds: [],
    LocationContactIds: [],
    TaskStatuses: [],
    TaskTypes: [0, 3],
    Filter: {IncludeWithNoDate: true}
}

export const getSetEventsByTypeFunc = (taskType) => {
    switch (taskType) {
        case ApiTimelineItemType.Task:
            return setEvents;
        case ApiTimelineItemType.Booking:
            return setBookings;
        default:
            return () => {
            }
    }
}

export const loadTimeline = (projectIds = [], timeZone, taskType = ApiTimelineItemType.Task, startDate, endDate) => {
    dispatch(setTimeLineLoading(true));
    const setEventsFunc = getSetEventsByTypeFunc(taskType);

    return api.post(`api/ProjectTimetable/GetByPage?timeZoneId=${encodeURIComponent(timeZone)}&projectId=`, {
        ...timelineDefaultFilter,
        ProjectIds: projectIds,
        taskType: taskType,
        startDate: startDate,
        endDate: endDate,
    }, `loadTimeline`)
        .then((resp) => {
            dispatch(setEventsFunc(resp));

            const selected = store.getState().mainTable.selected.map(s => resp.find(m => m.Id === s.Id));
            dispatch(setSelected(selected.filter(i => i)));
            dispatch(setTimeLineLoading(false));

            return resp;
        })
        .catch((er) => {
            if (!isRequestCanceled(er)) {
                dispatch(setTimeLineLoading(false));
            }
        });
}

export const getProjectEventDateRange = (projectIds = [], timeZone, taskType = ApiTimelineItemType.Task) => {
    dispatch(setTimeLineLoading(true));

    return api.post(`api/ProjectTimetable/GetProjectEventDateRange?timeZoneId=${encodeURIComponent(timeZone)}&projectId=`, {
        ...timelineDefaultFilter,
        ProjectIds: projectIds,
        taskType: taskType,
    }, `loadTimeline`)
        .then((resp) => {
            return resp;
        })
        .catch((er) => {
            if (!isRequestCanceled(er)) {
                dispatch(setTimeLineLoading(false));
            }
        });
}

export const refreshTimeline = (taskType) => {
    const state = store.getState();
    const projectIds = state.projects.activeProjects.map(p => p.Id);
    const selectedTimeZone = state.timeline.selectedTimeZone;
    const startDate = state.timeline.startDate;
    const endDate = state.timeline.endDate;

    if (projectIds.length === 0) {
        return Promise.resolve();
    }

    return loadTimeline(projectIds, selectedTimeZone, taskType, startDate, endDate);
}


export const getProjectEventDateRangeWithDefaults = (taskType = ApiTimelineItemType.Task) => {
    const projectIds = store.getState().projects.activeProjects.map(p => p.Id);
    const selectedTimeZone = store.getState().timeline.selectedTimeZone;

    if (projectIds.length === 0) {
        return Promise.resolve();
    }

    return getProjectEventDateRange(projectIds, selectedTimeZone, taskType);
}

export const createEvent = (projectId = "", payload, type) => api.post(`api/ProjectTask/Create?projectId=${projectId}`, payload);

export const updateEvent = (payload) => api.post(`api/ProjectTask/Update`, payload, `Update_Event_${payload.id}`)
    .then((response) => {
        loadTags();
        return response;
    });

export const updateSchedule = (payload) => api.post(`api/ProjectTask/UpdateSchedule`, payload, `Update_Schedule_Event${payload.id}`)
    .then((response) => {
        return response;
    });

export const getCreateOrUpdateBookingPayload = ({
                                                    id,
                                                    projectId,
                                                    taskId,
                                                    bookingStatus,
                                                    roles,
                                                    teamMember,
                                                    contact,
                                                    notes,
                                                    syncTimeFrame,
                                                    booking = {},
                                                    positionId,
                                                    labourLineId
                                                }) => ({
    BookingDto: {
        ...booking,
        Id: id,
        BookingStatus: bookingStatus,
        RoleTags: roles,
        TeamMemberId: teamMember,
        NewTeamMemberContact: contact,
        Notes: notes,
        SyncTimeFrame: syncTimeFrame,
        PositionId: positionId,
        LabourLineId: labourLineId
    }, ProjectId: projectId, NumberOfSlots: null, Task: {
        Id: taskId, ProjectId: projectId,
    }, CurrentProjectId: projectId,
});

export const createOrUpdateBooking = (payload) => {
    if (payload.BookingDto) {
        payload.BookingDto.Notes = sanitizeHTML(payload.BookingDto.Notes);
    }
    return api.post(`api/ProjectWorkCallBooking/CreateOrUpdate`, payload, payload.BookingDto?.Id ? `Update_Booking_${payload.BookingDto.Id}` : null);
}

export const deleteEvents = (ids) => api.post(`api/ProjectTask/BatchRemove?`, ids.map(Id => ({Id})));

export const deleteBooking = (ids) => api.post(`api/ProjectWorkCallBooking/Remove?`, {BookingsId: ids});

export const getEvent = (id, projectId, type) => api.get(`api/ProjectTimetable/Get?id=${id}&projectId=${projectId}&type=${type}`, `Get_Booking_${id}`);

export const makeFavorite = (payload) => api.post(`api/ProjectTimetable/MakeFavorite`, payload);

export const cloneToProjectBatch = (payload) => api.post(`api/ProjectTimetable/CloneToProjectBatch`, payload)
    .then((cloned) => refreshTimeline(ApiTimelineItemType.Task)
        .then((result) => cloned.map(c => result.find(r => r.Id === c.Id))));

export const shift = (payload) => api.post(`api/ProjectTimetable/Shift`, payload).then(() => refreshTimeline(ApiTimelineItemType.Task));

export const timelineImport = (projectId, payload) => api.post(`api/ProjectTaskImport/ParseCsv?projectId=${projectId}`, payload)

export const cloneBookings = (payload) => api.post(`api/ProjectWorkCallBooking/Clone`, payload)
    .then((result) => refreshTimeline(ApiTimelineItemType.Booking)
        .then(() => result));

export const exportBookings = (bookingIds, timeZoneId) => {
    const timestamp = moment().format("yyyy-MM-DDTHH-mm-ss");
    const activePortfolio = store.getState().projects.activePortfolio;
    const portfolio = activePortfolio.Contact.Company;

    const data = {
        AccountId: activePortfolio.Id, BookingIds: bookingIds, TimeZoneId: timeZoneId,
    };

    const fileName = `${portfolio}_CrewBookings_${timestamp}.csv`;

    api.fetchFile("api/ProjectWorkCallBooking/GetCsvFromBookings", fileName, data);
}

export const batchUpdateTasks = (payload) => api.post(`api/ProjectTimetable/BatchUpdate`, payload)
    .then((res) => Promise.all([refreshTimeline(ApiTimelineItemType.Task), loadTags()]).then(() => res))

export const batchUpdateBookings = (payload) => api.post(`api/ProjectWorkCallBooking/BatchUpdate`, payload)
    .then(() => Promise.all([refreshTimeline(ApiTimelineItemType.Booking), loadTags()]));


export const getBookingConflicts = (id) => api.get(`api/ProjectWorkCallBooking/GetPosibleConflicts?id=${id}`)

export const onTimelineImport = async (data) => {
    const projectId = store.getState().timeline.importingData;
    const result = await timelineImport(projectId, data)
    await loadTeam(projectId);
    return result;
} 
