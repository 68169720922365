import React, {useEffect, useState} from "react";
import _ from "lodash";
import ProductionBooksTable from "./ProductionBooksTable";
import {useSelector} from "react-redux";
import {loadProductionBooks} from "../../services/productionBooks";
import {refreshAllPortfolioProjects} from "../../services/projects";
import {loadTimeline} from "../../services/timeline";
import {loadTags} from "../../services/tags";
import {loadContacts} from "../../services/contacts";
import {refreshAttachments} from "../../services/attachments";

const ProductionBooks = () => {
    const [loading, setLoading] = useState(true);
    const items = _.sortBy(useSelector((state) => state.productionBooks.items) || [], (pb) => pb.Name);
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);
    const isPortfolioLoading = useSelector((state) => state.projects.isPortfolioLoading);

    useEffect(() => {
        if (!activePortfolioId || isPortfolioLoading) return;

        const loadData = async () => {
            try {
                await Promise.all([loadProductionBooks(activePortfolioId), loadContacts(activePortfolioId), refreshAttachments()]);
            } finally {
                setLoading(false);
            }
        };
        loadData();
    }, [activePortfolioId, isPortfolioLoading]);

    return <ProductionBooksTable items={items} loading={loading}/>;
};

export default ProductionBooks;
