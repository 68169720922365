import React from "react";
import _ from "lodash";
import {message} from "antd";
import {useDispatch} from "react-redux";

import "./ItemsTable.scss";

import {getResource} from "../../../services/resources";
import {ResourceType} from "../../../constants";
import {startLoading, endLoading} from "../../../redux/reducers/detailsPanel";

import TableWithGroups from "../../Common/Tables/TableWithGroups";
import EditAction from "../../Common/Tables/Actions/EditAction";
import {sortInventory} from "../../../helpers/lists";
import {infinity} from "../../../helpers/text";

import FilterBar from "./FilterBar/ItemsFilterBar";
import GroupItemsTable from "./GroupItemsTable";
import ItemsTableFooter, {GroupItemsTableFooter} from "./ItemsTableFooter";

const columns = [
    {
        title: "Item",
        dataIndex: "Name",
        key: "Id",
        width: "200px",
    },
    {
        title: "Inventory",
        dataIndex: "Sources",
        key: "Inv",
        width: "75px",
        render: (items = []) => {
            const unlimited = items.filter((m) => !m.Quantity && m.Quantity !== 0);
            const outOfStock = items.filter((m) => m.Quantity === 0);
            const present = items.filter((m) => m.Quantity > 0);

            if (present.length + outOfStock.length === 0 && unlimited.length > 0) return infinity;

            return _.sum(items.map((m) => m.Quantity || 0));
        },
    },
    {
        title: "Sources",
        dataIndex: "Sources",
        key: "Src",
        width: "auto",
        render: (items = []) =>
            sortInventory(items)
                .map((m) => m.Name)
                .join(", "),
    },
    {
        title: "Department",
        dataIndex: "DepartmentTag",
        key: "Dep",
        width: "150px",
        render: (department) => department?.Name,
    },
    {
        title: "Details",
        dataIndex: "Description",
        width: "auto",
    },
];

const ItemsTable = ({items = []}) => {
    const dispatch = useDispatch();

    function loadItemToEdit(item) {
        dispatch(startLoading({item: item, type: ResourceType.Item}));
        getResource(item.Id)
            .then((item) => dispatch(endLoading({item})))
            .catch(() => message.error("Server error"));
    }

    const EditItem = (props) => <EditAction onClick={loadItemToEdit} {...props} />;

    return (
        <TableWithGroups
            items={items}
            columns={columns}
            FilterBar={FilterBar}
            loadItemToEdit={loadItemToEdit}
            actions={[EditItem].filter((a) => a)}
            footer={ItemsTableFooter}
            GroupTable={GroupItemsTable}
            groupFooter={GroupItemsTableFooter}></TableWithGroups>
    );
};

export default ItemsTable;
