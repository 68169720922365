import React from "react";
import {DatePicker, Form} from "antd";
import {SelectWithFormItem, TextAreaWithFormItem} from "../../Common/Input";
import "./BatchEdit.scss";
import TeamMembersForEvent from "../DetailsPanel/TeamMembers/TeamMembers";
import ContactsForEvents from "../DetailsPanel/TeamMembers/ContactsForEvents";
import BulkEditableField from "../../Common/BulkEditableField/BulkEditableField";
import {TimeRangeWithPresets} from "../DetailsPanel/DateAndTime";
import {mapPositionToSelect, sortPositions} from "../../Requirements/Common/PositionSelector";

const {RangePicker} = DatePicker;
const BookingInputs = ({
                           form,
                           isSingleProject,
                           positions,
                           projectIds,
                           setIsQuickCreateOrgVisible,
                           setIsQuickCreateContactVisible,
                           statusOptions,
                           dateFormat,
                           timeFormat,
                           selectedTimeZone,
                       }) => (
    <>
        <BulkEditableField name="Date" form={form}>
            {(name, isFieldDisabled) => (
                <Form.Item label="Date" name={name} className="ant-form-item-without-validation">
                    <RangePicker
                        disabled={isFieldDisabled}
                        placeholder={["Start date", "End date"]}
                        format={dateFormat}
                        showTime={false}></RangePicker>
                </Form.Item>
            )}
        </BulkEditableField>
        <BulkEditableField name="Time" form={form}>
            {(name, isFieldDisabled) => (
                <>
                    <Form.Item label="Time" name={name} className="ant-form-item-without-validation">
                        <TimeRangeWithPresets
                            isDisabled={isFieldDisabled}
                            popupClassName="time-range-picker"
                            timeFormat={timeFormat}></TimeRangeWithPresets>
                    </Form.Item>
                </>
            )}
        </BulkEditableField>
        <Form.Item wrapperCol={{offset: 8}} className="ant-form-item-without-validation">
            {selectedTimeZone}
        </Form.Item>

        <BulkEditableField name="PositionId" form={form}>
            {(name, isFieldDisabled) => (
                <SelectWithFormItem
                    disabled={isFieldDisabled}
                    label="Position"
                    name={name}
                    style={{width: "100%"}}
                    placeholder="Select position"
                    options={sortPositions(positions).map((p) => mapPositionToSelect(p))}></SelectWithFormItem>
            )}
        </BulkEditableField>
        {isSingleProject && (
            <BulkEditableField name="TeamMemberContactIds" form={form}>
                {(name, isFieldDisabled) => (
                    <TeamMembersForEvent
                        form={form}
                        allowClear={true}
                        singleContactMode={true}
                        peopleOnly={true}
                        projectId={projectIds[0]}
                        isOptionalFieldDisabled={isFieldDisabled}
                        name={name}
                        label={"Contact"}
                        setIsQuickCreateOrgVisible={setIsQuickCreateOrgVisible}
                        setIsQuickCreateContactVisible={setIsQuickCreateContactVisible}
                        onChanged={(data) => {
                            // form.setFieldsValue({ ContactId: data.TeamMemberContactIds[0] });
                        }}></TeamMembersForEvent>
                )}
            </BulkEditableField>
        )}
        {!isSingleProject && (
            <BulkEditableField name="TeamMemberContactIds" form={form}>
                {(name, isFieldDisabled) => (
                    <ContactsForEvents
                        allowClear={true}
                        singleContactMode={true}
                        peopleOnly={true}
                        isOptionalFieldDisabled={isFieldDisabled}
                        name={name}
                        setIsQuickCreateOrgVisible={setIsQuickCreateOrgVisible}
                        setIsQuickCreateContactVisible={setIsQuickCreateContactVisible}
                        onChanged={() => {
                        }}></ContactsForEvents>
                )}
            </BulkEditableField>
        )}
        <BulkEditableField name="BookingStatus" form={form}>
            {(name, isFieldDisabled) => (
                <SelectWithFormItem
                    allowClear={true}
                    label="Booking Status"
                    name={name}
                    showSearch={true}
                    placeholder="Select a Status"
                    options={statusOptions}
                    disabled={isFieldDisabled}
                />
            )}
        </BulkEditableField>
        <BulkEditableField name="Notes" form={form}>
            {(name, isFieldDisabled) => (
                <TextAreaWithFormItem
                    label="Details"
                    disabled={isFieldDisabled}
                    name={name}
                    useTextEditor={true}
                    placeholder="Enter details"></TextAreaWithFormItem>
            )}
        </BulkEditableField>
    </>
);

export default BookingInputs;
