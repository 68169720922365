import {createSlice} from '@reduxjs/toolkit';
import moment from 'moment';
import {ViewMode} from "../../constants";

const initialState = {
    events: [],
    bookings: [],
    selectedTimeZone: null,
    isLoading: false,
    filterDateRange: [],
    importingData: null,
    startDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
    endDate: moment().add(1, 'year').startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
    currentView: ViewMode.List,
};

const timelineSlice = createSlice({
    name: 'timeline',
    initialState,
    reducers: {
        setEvents(state, action) {
            state.events = action.payload;
        },
        setBookings(state, action) {
            state.bookings = action.payload;
        },
        setSelectedTimeZone(state, action) {
            state.selectedTimeZone = action.payload;
        },
        setTimeLineLoading(state, action) {
            state.isLoading = action.payload;
        },
        setFilterDateRange(state, action) {
            state.filterDateRange = action.payload;
        },
        setImportingData(state, action) {
            state.importingData = action.payload;
        },
        setDateSelectorRange(state, action) {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setCurrentView: (state, action) => {
            state.currentView = action.payload;
        },
    },
});

export const {
    setEvents,
    setBookings,
    setSelectedTimeZone,
    setTimeLineLoading,
    setFilterDateRange,
    setImportingData,
    setDateSelectorRange,
    setCurrentView
} = timelineSlice.actions;

export default timelineSlice.reducer;