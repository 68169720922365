import React from 'react';

const WithQuickInfoHeaderProps = (Component, props) => {
    return (headerProps) => (
        <Component
            {...headerProps}
            handleEditClick={props.handleEditClick}
            handleCloneClick={props.handleCloneClick}
            handleDeleteClick={props.handleDeleteClick}
            scheduleObj={props.scheduleObj}
        />
    );
};

export default WithQuickInfoHeaderProps;