import {useSelector} from "react-redux";

const defaultDateFormat = "MM/DD/YY";
const defaultDateTimeFormat = "MM/DD/YY h:mma";
const defaultDateTimeShortFormat = "MM/DD h:mma";
const defaultDateLongNoYearFormat = "MMM D";
const defaultDayDateLongNoYearFormats = "ddd, MMM D";

const universalDateFormat = "yyyy-MM-DD";
const importTimeFormat = "HH:mm:ss";
const monthYearFull = "MMMM yyyy";

const dateFormats = Object.freeze({
    1: "MM/DD/YY",
    2: "DD/MM/YY",
});

const csvDateFormats = Object.freeze({
    1: "MM/DD/YYYY",
    2: "DD/MM/YYYY",
});

const dateShortFormats = Object.freeze({
    1: "MM/DD",
    2: "DD/MM",
});

const dateLongNoYearFormats = Object.freeze({
    1: "MMM D",
    2: "D MMM",
});

const dayDateLongNoYearFormats = Object.freeze({
    1: "ddd, MMM D",
    2: "ddd, D MMM",
});

const timeFormats = Object.freeze({
    1: "h:mma",
    2: "HH:mm",
});

const csvTimeFormats = Object.freeze({
    1: "h:mm a",
    2: "HH:mm",
});

const useDateTimeFormat = () => {
    const {Date, Time} = useSelector((state) => state.currentUser.user.DateTimeSettings);

    const dateFormat = dateFormats[Date.Value] || defaultDateFormat;
    const dateShortFormat = dateShortFormats[Date.Value] || defaultDateTimeShortFormat;
    const timeFormat = timeFormats[Time.Value] || defaultDateTimeFormat;
    const dateLongNoYearFormat = dateLongNoYearFormats[Date.Value] || defaultDateLongNoYearFormat;
    const dateLongFormat = `${dateLongNoYearFormats[Date.Value] || defaultDateLongNoYearFormat}, YYYY`;

    const dayDateLongNoYearFormat = dayDateLongNoYearFormats[Date.Value] || defaultDayDateLongNoYearFormats;
    const dayDateLongFormat = `${dayDateLongNoYearFormats[Date.Value] || defaultDayDateLongNoYearFormats}, YYYY`;
    const dateTimeWithTime = `${dateLongNoYearFormats[Date.Value] || defaultDayDateLongNoYearFormats}, YYYY (${timeFormats[Time.Value]})`;

    return {
        date: dateFormat,
        csvDate: csvDateFormats[Date.Value],
        csvTime: csvTimeFormats[Time.Value],
        time: timeFormat,
        dateTime: `${dateFormat} ${timeFormat}`,
        dateAtTime: `${dateFormat} [at] ${timeFormat}`,
        dateAtTimeShort: `${dateShortFormat} [at] ${timeFormat}`,
        dateLongFormat,
        dateLongNoYearFormat,
        importTimeFormat,
        universalDateFormat,
        monthYearFull,
        dayDateLongNoYearFormat,
        dayDateLongFormat,
        dateTimeWithTime,
    };
};

export default useDateTimeFormat;
