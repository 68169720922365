import {ApiContactType} from "../constants";
import _ from "lodash";

const formatContactName = (contact = {}, withPronouns = true) => {
    if (contact?.Type === ApiContactType.Person) {
        let name = `${contact.Nickname || contact.FirstName || ""} ${contact.LastName || ""}`.trim();
        if (contact.Pronouns && withPronouns) {
            name = `${name} (${contact.Pronouns})`;
        }
        return name;

    } else {
        return contact?.Company;
    }
}

const formatLocationName = (contact, expanded = true) => {
    const expand = expanded || contact.Parent?.Company !== contact.Company
    return contact?.Type === ApiContactType.Space && expand
        ? skipPlaceSpaceSameName(contact)
        : formatContactName(contact, false);
};

const skipPlaceSpaceSameName = (contact) => {
    const placeName = contact.Parent?.AlsoKnownAs || contact.Parent?.Company;
    if (contact.Company === placeName) return contact.Company;

    return `${contact.Company} (${contact.Parent?.AlsoKnownAs || contact.Parent?.Company})`
}

const formatTeamMemberName = (teamMember = {}, hideRoles = false) => {
    const contactName = teamMember.Contact ? formatContactName(teamMember.Contact, false) : `Team Member ${teamMember.Id}`
    return teamMember.RoleTags.length === 0 || hideRoles ? contactName : `${contactName} (${teamMember.RoleTags.map(x => x.Name).join(", ")})`
}

const formatTimelineTeamMemberName = (teamMember = {}) => {
    if (teamMember.ContactId) {
        return teamMember.Name;
    }

    if (teamMember.RoleTags.length > 0) {
        return `(${teamMember.RoleTags.map(x => x.Name).join(", ")})`;
    }

    return `Team Member ${teamMember.Id}`;
}

const formatCrewTeamMemberName = (teamMember = {}) => {
    const name = teamMember.Name ? teamMember.Name : `Team Member ${teamMember.Id}`;
    return name;
}

const getEnumTitle = (enm, value) => _.keys(enm).find((k) => enm[k] === value)

const infinity = "∞";

const formatInventory = (sources, pureValues = false) => {
    const src = sources || [];

    if (pureValues)
        return _.sum(src.map(s => s || 0))

    return _.sum(src.map(s => s?.Quantity || 0));
};

const formatQuantity = (quantity) => {
    return !quantity ? infinity : quantity.toString();
};

const trimEnd = (str, toRemove = " ", splitMode = false) => {
    let s = str?.toString();
    const r = !toRemove?.toString();
    if (!s || !r) return s;

    let i = s.length - 1;

    if (splitMode) {
        while (i > 0 && toRemove.includes(s.charAt(i))) i--;
    } else {
        while (i > 0 && s.charAt(i) == toRemove) i--;
    }

    return i > 0 ? s.substring(0, i) : "";
};

const formatToFixedFlex = (number) => {
    const parts = number?.toString().split('.');
    if (parts.length > 1) {
        const left = parts.filter((_, j) => j < parts.length - 1).join(".");
        const right = trimEnd(parts[parts.length - 1]?.toString(), '0');
        return `${left}.${right}`;
    }

    return number?.toString();
};

const formatPosition = ({Name, Source}) => (Source ? `${Name} (${Source.Name})` : Name);


export {
    formatContactName,
    formatTeamMemberName,
    formatLocationName,
    getEnumTitle,
    infinity,
    formatInventory,
    formatQuantity,
    trimEnd,
    formatToFixedFlex,
    formatPosition,
    formatTimelineTeamMemberName,
    formatCrewTeamMemberName
};
