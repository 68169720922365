import React, {useEffect, useState} from "react";
import {MultipleFilterWithSearch} from "../../Tables/FilterBar/MultipleFilterWithSearchFilter";
import {Button, Dropdown, Space} from "antd";
import {ControlOutlined} from "@ant-design/icons";

export const columnVisibilityFilter = (column, visibleColumns = []) => visibleColumns.length === 0 || column.alwaysVisible || visibleColumns.includes(column.dataIndex || column.key);

const ColumnsVisibility = ({columns = [], onOk, selected = []}) => {
    const [isVisible, setVisible] = useState(false);

    const [sessionItems, setSessionItems] = useState([]);

    const reset = () => {
        setSessionItems(columns.filter((c) => selected.length === 0 || selected.includes(c.value)));
    };
    useEffect(() => {
        reset();
    }, [selected]);

    function onChanged(selectedItems) {
        setSessionItems(selectedItems);
    }

    const selectAllText = "(select all)";

    const submitFilter = () => {
        const filterValues = sessionItems.map((i) => i.value);
        setVisible(false);
        onOk(filterValues);
    };

    const cancelFilter = () => {
        setVisible(false);
        reset();
    };

    return (<Dropdown
        visible={isVisible}
        overlay={<MultipleFilterWithSearch
            items={columns}
            selectedItems={sessionItems}
            onChanged={onChanged}
            selectAllText={selectAllText}
            className={`projects-scroll`}
            buttons={<div className="right">
                <Space>
                    <Button onClick={() => cancelFilter()}>Cancel</Button>
                    <Button disabled={sessionItems.length === 0} onClick={() => submitFilter()}
                            type="primary">
                        Ok
                    </Button>
                </Space>
            </div>}
        />}
        className={`multiple-items-filter`}>
        <Button onClick={() => setVisible(true)} type="link" className="btn-multiple-items-filter">
            <ControlOutlined className="select-columns-icon"/>
        </Button>
    </Dropdown>);
};

export default ColumnsVisibility;
