import React from "react";
import _ from "lodash";
import {message} from "antd";
import {getContact} from "../../services/contacts";
import FilterBar from "./FilterBar/ContactsFilterBar";
import {ContactType} from "../../constants";
import {startLoading, endLoading} from "../../redux/reducers/detailsPanel";
import {useDispatch} from "react-redux";
import GroupContactsTable from "./GroupContactsTable";
import DeleteContact from "./DeleteContact";
import TableWithGroups from "../Common/Tables/TableWithGroups";
import EditAction from "../Common/Tables/Actions/EditAction";
import {formatContactName} from "../../helpers/text";
import {isRequestCanceled} from "../../helpers/error";

const defaultColumns = {
    name: {
        title: "Name",
        dataIndex: "Company",
        key: "Id",
    },
    defaultRoles: {
        title: "Default Roles",
        dataIndex: "Specialties",
        width: "20%",
        render: (items = []) => items.map((x) => x.Name).join(", "),
    },
    defaultGroups: {
        title: "Default Groups",
        dataIndex: "Groups",
        width: "20%",
        render: (items = []) => items.map((x) => x.Name).join(", "),
    },
};

export const peopleColumns = [
    {
        title: "Name",
        dataIndex: "FirstName",
        key: "Id",
        width: "20%",
        render: (n, item) => formatContactName(item),
    },
    {
        title: "Organization",
        dataIndex: "Parent",
        key: "Id",
        width: "20%",
        render: (x) => formatContactName(x),
    },
    defaultColumns.defaultRoles,
    defaultColumns.defaultGroups,
    {
        title: "Email",
        dataIndex: "EmailAddresses",
        render: (emailAddresses = []) => (emailAddresses.length > 0 ? emailAddresses[0].EmailAddress : ""),
    },
];

export const organizationsColumns = [defaultColumns.name, defaultColumns.defaultRoles, defaultColumns.defaultGroups];

export const locationsColumns = [
    defaultColumns.name,
    defaultColumns.defaultRoles,
    defaultColumns.defaultGroups,
    {
        title: "Spaces",
        dataIndex: "spaces",
        render: (spaces) => (
            <span>
        {_.sortBy(spaces || [], ["Company"])
            .map((s) => formatContactName(s))
            .join(", ")}
      </span>
        ),
    },
];

const contactColumns = {
    [ContactType.Person]: peopleColumns,
    [ContactType.Organization]: organizationsColumns,
    [ContactType.Location]: locationsColumns,
};

const ContactsTable = ({items = [], contactType}) => {
    const dispatch = useDispatch();

    function loadItemToEdit(contact) {
        dispatch(startLoading({item: contact, type: contactType}));
        getContact(contact.Id)
            .then((contact) => dispatch(endLoading({item: contact})))
            .catch((err) => {
                if (!isRequestCanceled(err)) message.error("Server error");
            });
    }

    const EditContact = (props) => <EditAction onClick={loadItemToEdit} {...props} />;

    return (
        <TableWithGroups
            items={items}
            columns={contactColumns[contactType]}
            FilterBar={FilterBar}
            GroupTable={GroupContactsTable}
            loadItemToEdit={loadItemToEdit}
            actions={[EditContact, DeleteContact]}></TableWithGroups>
    );
};

export default ContactsTable;
