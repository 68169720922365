import React, {useEffect} from "react";
import {Drawer, Spin} from "antd";
import "./DetailsPanel.scss";
import {useDispatch, useSelector} from "react-redux";

import {
    BulbFilled,
    BulbOutlined,
    ClockCircleFilled,
    EnvironmentOutlined,
    FileTextFilled,
    FolderOpenFilled,
    PaperClipOutlined,
    SendOutlined,
    ShopOutlined,
    ToolFilled,
    ToolOutlined,
    UnorderedListOutlined,
    UserOutlined,
} from "@ant-design/icons";
import {ApiContactType, ContactType, RequirementType, ResourceType, SidePanelItemType} from "../../constants/constants";
import {close, detailsSubPanel} from "../../redux/reducers/detailsPanel";
import ContactDetailsPanel from "../Contacts/DetailsPanel";
import ResourceDetailsPanel from "../Resources/Items/DetailsPanel";
import CollectionDetailsPanel from "../Resources/Collections/DetailsPanel";
import UsageDetailsPanel from "../Resources/Usage/DetailsPanel";
import PositionDetailsPanel from "../Resources/Positions/DetailsPanel";
import ProjectDetailsPanel from "../Projects/DetailsPanel";
import TeamMemberDetailsPanel from "../Team/DetailsPanel";
import TimelineDetailsPanel from "../Timeline/DetailsPanel";
import RequirementDetailsPanel from "../Requirements/RequirementItems/DetailsPanel";
import BookingDetailsPanel from "../Timeline/DetailsPanel/BookingDetailsPanel";
import AttachmentDetailsPanel from "../Attachments/DetailsPanel";
import ProductionBooksDetailsPanel from "../ProductionBooks/DetailsPanel";
import ReportsDetailsPanel from "../Reports/DetailsPanel/ReportsDetailsPanel";
import ReportsDetailsPanelTitle from "../Reports/DetailsPanel/ReportsDetailsPanelTitle";
import ReportTemplateDetailsPanel from "../Reports/DetailsPanel/ReportTemplateDetailsPanel";

import {useLocation} from "react-router-dom";
import ContactDetailsPanelTitle from "../Contacts/DetailsPanel/ContactDetailsPanelTitle";
import TeamMemberDetailsPanelTitle from "../Team/DetailsPanel/TeamMemberDetailsPanelTitle";
import ProjectDetailsPanelTitle from "../Projects/DetailsPanel/ProjectDetailsPanelTitle";
import ReportTemplateDetailsPanelTitle from "../Reports/DetailsPanel/ReportTemplateDetailsPanelTitle";

import ResourceDetailsPanelTitle from "../Resources/Items/DetailsPanel/DetailsPanelTitle";
import CollectionDetailsPanelTitle from "../Resources/Collections/DetailsPanel/DetailsPanelTitle";
import UsageDetailsPanelTitle from "../Resources/Usage/DetailsPanel/DetailsPanelTitle";
import PositionDetailsPanelTitle from "../Resources/Positions/DetailsPanel/DetailsPanelTitle";

import RequirementDetailsPanelTitle from "../Requirements/RequirementItems/DetailsPanel/RequirementDetailsPanelTitle";
import LabourLineDetailsPanel from "../Requirements/Labour/DetailsPanel/LabourLineDetailsPanel";
import {formatPosition, formatTeamMemberName} from "../../helpers/text";
import NotesDetailsPanel from "../Timeline/Notes/DetailsPanel/NotesDetailsPanel";
import ContactsSearchSubPanel from "../Timeline/DetailsPanel/TeamMembers/ContactsSearchSubPanel";
import {sanitizeHTML} from "../../helpers/html";
import moment from "moment";
import useDateTimeFormat from "../../hooks/useDateTimeFormat";

const {close: closeSubPanel} = detailsSubPanel;

const detailsPanelIcons = {
    [ContactType.Person]: <UserOutlined/>,
    [ContactType.Organization]: <ShopOutlined/>,
    [ContactType.Location]: <EnvironmentOutlined/>,
    [SidePanelItemType.Project]: <FolderOpenFilled/>,
    [SidePanelItemType.TeamPerson]: <UserOutlined/>,
    [SidePanelItemType.TeamOrganization]: <ShopOutlined/>,
    [SidePanelItemType.TeamLocation]: <EnvironmentOutlined/>,
    [SidePanelItemType.Event]: <ClockCircleFilled/>,
    [SidePanelItemType.Booking]: <ToolFilled/>,
    [SidePanelItemType.Attachment]: <PaperClipOutlined/>,
    [SidePanelItemType.ProductionBooks]: <SendOutlined/>,
    [SidePanelItemType.Reports]: <FileTextFilled/>,
    [SidePanelItemType.ReportsOld]: <FileTextFilled/>,
    [SidePanelItemType.ReportTemplate]: <FileTextFilled/>,
    [ResourceType.Item]: <BulbFilled/>,
    [ResourceType.Collection]: <BulbFilled/>,
    [ResourceType.Usage]: <BulbFilled/>,
    [ResourceType.Position]: <ToolOutlined/>,
    [RequirementType.Item]: <BulbOutlined/>,
    [RequirementType.Labour]: <ToolOutlined/>,
    [SidePanelItemType.Notes]: <UnorderedListOutlined/>,
    [SidePanelItemType.ContactsSearch]: <UserOutlined/>,
};

const detailsPanelNewTitleText = {
    [ContactType.Person]: "New Person",
    [ContactType.Organization]: "New Organization",
    [ContactType.Location]: "New Location",
    [SidePanelItemType.Project]: "New Project",
    [SidePanelItemType.TeamPerson]: "Team Member",
    [SidePanelItemType.TeamOrganization]: "Team Member",
    [SidePanelItemType.TeamLocation]: "Team Member",
    [SidePanelItemType.Event]: "New Event",
    [SidePanelItemType.Booking]: "Booking",
    [SidePanelItemType.Attachment]: "Attachment",
    [SidePanelItemType.ProductionBooks]: "Production Book",
    [SidePanelItemType.Reports]: "Reports",
    [ResourceType.Item]: "Resource",
    [ResourceType.Collection]: "Resource Collection",
    [ResourceType.Position]: "Position",
    [RequirementType.Item]: "Requirement",
    [RequirementType.Labour]: "Labour Line",
};

const mainComponent = {
    [ContactType.Person]: () => <ContactDetailsPanel apiContactType={ApiContactType.Person}
                                                     contactType={ContactType.Person}/>,
    [ContactType.Organization]: () => (
        <ContactDetailsPanel apiContactType={ApiContactType.Organization} contactType={ContactType.Organization}/>),
    [ContactType.Location]: () => <ContactDetailsPanel apiContactType={ApiContactType.Place}
                                                       contactType={ContactType.Location}/>,
    [SidePanelItemType.Project]: () => <ProjectDetailsPanel/>,
    [SidePanelItemType.TeamPerson]: () => <TeamMemberDetailsPanel type={SidePanelItemType.TeamPerson}/>,
    [SidePanelItemType.TeamOrganization]: () => <TeamMemberDetailsPanel type={SidePanelItemType.TeamOrganization}/>,
    [SidePanelItemType.TeamLocation]: () => <TeamMemberDetailsPanel type={SidePanelItemType.TeamLocation}/>,
    [SidePanelItemType.Event]: () => <TimelineDetailsPanel/>,
    [SidePanelItemType.Booking]: () => <BookingDetailsPanel/>,
    [SidePanelItemType.Attachment]: () => <AttachmentDetailsPanel/>,
    [SidePanelItemType.ProductionBooks]: () => <ProductionBooksDetailsPanel/>,
    [SidePanelItemType.Reports]: (props) => <ReportsDetailsPanel {...props} />,
    [SidePanelItemType.ReportTemplate]: () => <ReportTemplateDetailsPanel/>,
    [ResourceType.Item]: () => <ResourceDetailsPanel/>,
    [ResourceType.Collection]: () => <CollectionDetailsPanel/>,
    [ResourceType.Usage]: () => <UsageDetailsPanel/>,
    [ResourceType.Position]: () => <PositionDetailsPanel/>,
    [RequirementType.Item]: () => <RequirementDetailsPanel/>,
    [RequirementType.Labour]: () => <LabourLineDetailsPanel/>,
    [SidePanelItemType.Notes]: () => <NotesDetailsPanel/>,
    [SidePanelItemType.ContactsSearch]: () => <ContactsSearchSubPanel/>,
};

const stripPTags = (html) => {
    return html.replace(/<[^>]*>?/gm, '');
}

const getHeaderEditComponent = (type, editedItem, isSubPanel) => {
    switch (type) {
        case ContactType.Person:
        case ContactType.Organization:
        case ContactType.Location:
            return <ContactDetailsPanelTitle type={type} editedItem={editedItem}></ContactDetailsPanelTitle>;

        case SidePanelItemType.TeamPerson:
        case SidePanelItemType.TeamOrganization:
        case SidePanelItemType.TeamLocation:
            return <TeamMemberDetailsPanelTitle type={type} editedItem={editedItem}></TeamMemberDetailsPanelTitle>;

        case SidePanelItemType.Booking:
            return (
                <>
                    <DetailsPanelIcon type={type}/>
                    {editedItem.PeopleOrganizations && editedItem.PeopleOrganizations.length > 0 ? (
                        <>
                            {formatTeamMemberName(editedItem.PeopleOrganizations[0], true)}
                            {editedItem.LabourLine?.Position && (
                                <> ({formatPosition(editedItem.LabourLine?.Position || {})})</>
                            )}
                        </>
                    ) : (
                        editedItem.LabourLine?.Position && (
                            <>{formatPosition(editedItem.LabourLine?.Position || {})}</>
                        )
                    )}
                </>
            );
        case SidePanelItemType.Project:
            return <ProjectDetailsPanelTitle editedItem={editedItem}></ProjectDetailsPanelTitle>;
        case SidePanelItemType.Event: {
            return (<>
                {editedItem.IsProjectTask ? <FolderOpenFilled/> : <ClockCircleFilled/>} {editedItem.Name}{" "}
            </>);
        }
        case SidePanelItemType.Reports:
            return <ReportsDetailsPanelTitle editedItem={editedItem} isSubPanel={isSubPanel}/>;
        case SidePanelItemType.Attachment:
        case SidePanelItemType.ProductionBooks:
        case SidePanelItemType.ReportsOld:
            return (<>
                <DetailsPanelIcon type={type}/> {editedItem.Name}{" "}
            </>);

        case RequirementType.Labour:
            return (<>
                <DetailsPanelIcon type={type}/> {editedItem.Task?.Name}{" "}
            </>);
        case SidePanelItemType.ContactsSearch:
            return (<>
                <DetailsPanelIcon type={type}/> Select a Contact
            </>);

        case SidePanelItemType.ReportTemplate:
            return <ReportTemplateDetailsPanelTitle editedItem={editedItem}></ReportTemplateDetailsPanelTitle>;
        case ResourceType.Item:
            return <ResourceDetailsPanelTitle editedItem={editedItem}/>;
        case ResourceType.Collection:
            return <CollectionDetailsPanelTitle editedItem={editedItem}/>;
        case ResourceType.Usage:
            return <UsageDetailsPanelTitle editedItem={editedItem}/>;
        case RequirementType.Item:
            return <RequirementDetailsPanelTitle editedItem={editedItem}/>;
        case ResourceType.Position:
            return <PositionDetailsPanelTitle editedItem={editedItem}/>;
        case SidePanelItemType.Notes:
            return (<div className="notes-details-panel-title">
                <DetailsPanelIcon type={type}/>{" "}
                <span dangerouslySetInnerHTML={{__html: sanitizeHTML(stripPTags(editedItem.Text))}}/></div>);
        default:
            return "";
    }
};

const DetailsPanelIcon = ({type}) => <>{detailsPanelIcons[type] || null}</>;

const DetailsPanelHeaderNew = ({type, date}) => (<>
    <DetailsPanelIcon
        type={type}/> {detailsPanelNewTitleText[type]}{type === SidePanelItemType.Event && date ? `: ${date}` : ""}
</>);

const DetailsPanelHeader = ({type, isNew, editedItem, isSubPanel, date}) => isNew ?
    <DetailsPanelHeaderNew type={type} date={date}/> : getHeaderEditComponent(type, editedItem, isSubPanel);

const DetailsPanelContent = ({type, isSubPanel}) => (mainComponent[type] ? mainComponent[type]({isSubPanel}) : null);

const getWidth = (type) => {
    switch (type) {
        case SidePanelItemType.ContactsSearch:
            return 500;
        case SidePanelItemType.Reports:
        case SidePanelItemType.ReportTemplate:
            return 900;
        case ResourceType.Item:
            return 600;
        default:
            return 480;
    }
};

const DetailsPanel = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const {item: editedItem} = useSelector((state) => state.detailsPanel);
    const {item: editedItemSubPanel} = useSelector((state) => state.detailsSubPanel);
    const isNew = editedItem == null;
    const isNewSubPanel = editedItemSubPanel == null;
    const dateTimeFormats = useDateTimeFormat();
    const temporaryDates = useSelector((state) => state.detailsPanel.temporaryDates);

    useEffect(() => {
        onClose();
        onCloseSubPanel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]); 

    function onClose() {
        dispatch(close());
    }

    function onCloseSubPanel() {
        dispatch(closeSubPanel());
    }

    const {isOpen, type, isLoading} = useSelector((state) => state.detailsPanel);
    const {
        isOpen: isOpenSubPanel, type: typeSubPanel, isLoading: isLoadingSubPanel,
    } = useSelector((state) => state.detailsSubPanel);

    const formatDate = (startDate = null, endDate = null, startTime = null, endTime = null, formats) => {
        const mStartDate = moment(startDate);
        const mEndDate = moment(endDate);
        const mStartTime = moment(startTime, "HH:mm");
        const mEndTime = moment(endTime, "HH:mm");

        const formatWithYear = formats.dateLongFormat;
        const formatWithoutYear = formats.dateLongNoYearFormat;
        const timeFormat = formats.time;

        if (!mStartDate.isValid() && !mEndDate.isValid()) {
            return moment().format(formatWithYear);
        }

        if (mStartDate.isValid() && !mEndDate.isValid()) {
            return `${mStartDate.format(formatWithYear)}${mStartTime.isValid() ? ` (${mStartTime.format(timeFormat)})` : ""}`;
        }

        const daysDiff = mEndDate.diff(mStartDate, "days");

        if (daysDiff === 0) {
            return `${mStartDate.format(formatWithYear)}${mStartTime.isValid() && mEndTime.isValid() ? ` (${mStartTime.format(timeFormat)} - ${mEndTime.format(timeFormat)})` : mStartTime.isValid() ? ` (${mStartTime.format(timeFormat)})` : ""}`;
        }

        return `${mStartDate.format(formatWithoutYear)}${mStartTime.isValid() ? ` (${mStartTime.format(timeFormat)})` : ""} - ${mEndDate.format(formatWithYear)}${mEndTime.isValid() ? ` (${mEndTime.format(timeFormat)})` : ""}`;
    };

    const formattedDate = formatDate(temporaryDates.StartDate, temporaryDates.EndDate, temporaryDates.StartTime, temporaryDates.EndTime, dateTimeFormats);

    return (<Drawer
        title={<DetailsPanelHeader type={type} isNew={isNew} editedItem={editedItem} date={formattedDate}/>}
        closable={true}
        visible={isOpen}
        mask={true}
        maskStyle={{opacity: 0, animationDuration: "0s"}}
        onClose={onClose}
        className="details-panel"
        width={Math.max(getWidth(type), getWidth(typeSubPanel))}>
        <Spin spinning={isLoading}>
            <DetailsPanelContent type={type}/>
            <Drawer
                title={<DetailsPanelHeader
                    type={typeSubPanel}
                    isNew={isNewSubPanel}
                    isSubPanel={true}
                    editedItem={editedItemSubPanel}
                    date={formattedDate}
                />}
                closable={true}
                visible={isOpenSubPanel}
                mask={true}
                onClose={onCloseSubPanel}
                className="details-panel sub-details-panel"
                width={getWidth(typeSubPanel)}>
                <Spin spinning={isLoadingSubPanel}>
                    <DetailsPanelContent type={typeSubPanel} isSubPanel={true}/>
                </Spin>
            </Drawer>
        </Spin>
    </Drawer>);
};

export default DetailsPanel;
