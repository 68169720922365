import React from "react";
import _ from "lodash";
import {message} from "antd";
import {useDispatch} from "react-redux";

import {getCollection} from "../../../services/resources";
import {ResourceType} from "../../../constants";
import {startLoading, endLoading} from "../../../redux/reducers/detailsPanel";

import TableWithGroups from "../../Common/Tables/TableWithGroups";
import EditAction from "../../Common/Tables/Actions/EditAction";

import FilterBar from "./FilterBar";
import DeleteCollection from "./DeleteCollection";

const columns = [
    {
        title: "Collection",
        dataIndex: "Name",
        key: "Id",
    },
    {
        title: "Details",
        dataIndex: "Description",
    },
];

const CollectionsTable = ({collections = []}) => {
    const dispatch = useDispatch();

    function loadItemToEdit(item) {
        dispatch(startLoading({item: item, type: ResourceType.Collection}));
        getCollection(item.Id)
            .then((item) => dispatch(endLoading({item})))
            .catch(() => message.error("Server error"));
    }

    const EditItem = (props) => <EditAction onClick={loadItemToEdit} {...props} />;
    const DeleteItem = (props) => <DeleteCollection className="delete btn-table-actions" {...props} />;

    return (
        <TableWithGroups
            items={collections}
            columns={columns}
            FilterBar={FilterBar}
            loadItemToEdit={loadItemToEdit}
            actions={[EditItem, DeleteItem]}></TableWithGroups>
    );
};

export default CollectionsTable;
