import React, {useState} from "react";
import moment from "moment";
import TableWithGroups from "../../Common/Tables/TableWithGroups";
import FilterBar from "./FilterBar/FilterBar";
import GroupNotesTable from "./GroupNotesTable";
import {useDispatch, useSelector} from "react-redux";
import {Button, message} from "antd";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";
import {getNotes, markNoteDone} from "../../../services/reports";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {SidePanelItemType} from "../../../constants/constants";
import {openEdit} from "../../../redux/reducers/detailsPanel";
import {setNotes} from "../../../redux/reducers/reports";
import {ProjectTagForId} from "../../Common/Projects/ProjectsTag";
import {sanitizeHTML} from "../../../helpers/html";

const DueDateColumn = ({dueDate, dateFormat, isDone}) => {
    if (!dueDate) return "";

    const showOverdueIcon = !isDone && moment(dueDate).isBefore(moment(), "day");
    return (
        dueDate && (
            <>
                {showOverdueIcon &&
                    <ExclamationCircleFilled className="error-icon"/>} {moment(dueDate).format(dateFormat)}
            </>
        )
    );
};

const Notes = ({items, loading}) => {
    const selectedProjects = useSelector((state) => state.projects.activeProjects);
    var {date} = useDateTimeFormat();
    const dispatch = useDispatch();

    const columns = [
        {
            title: "Project",
            className: "project-column",
            dataIndex: ["ReportInfo", "ProjectId"],
            key: "Id",
            render: (projectId) => <ProjectTagForId projectId={projectId}/>,
            width: "180px",
        },
        {
            title: "Note",
            dataIndex: "Text",
            key: "Id",
            render: (data) => {
                const sanitizedHTML = sanitizeHTML(data);
                return <div dangerouslySetInnerHTML={{__html: sanitizedHTML}}/>
            }
        },
        {
            title: "Departments",
            dataIndex: "DepartmentTags",
            key: "Id",
            width: "200px",
            render: (items) => (items || []).map((x) => x.Name).join(", "),
        },
        {
            title: "Due Date",
            dataIndex: "DueDate",
            key: "Id",
            width: "95px",
            render: (value, record) => <DueDateColumn isDone={record.IsDone} dueDate={value} dateFormat={date}/>,
        },
        {
            title: "Status",
            dataIndex: ["Status", "Name"],
            key: "Id",
            width: "100px",
        },
        {
            title: "Linked Report",
            dataIndex: "ReportInfo",
            className: "text-nowrap",
            key: "Id",
            width: "200px",
            render: (reportInfo) => getLinkedReport(reportInfo, date),
        },
    ];

    const onDoneClick = (record, setDoneLoading) => {
        return markNoteDone(record.LinkedIds, !record.IsDone)
            .then(() => getNotes(selectedProjects.map((x) => x.Id)))
            .then((notes) => {
                setDoneLoading(false);
                dispatch(setNotes(notes));
            })
            .then(() => message.success(`Note marked as ${record.IsDone ? "Not Done" : "Done"}.`));
    };

    const MakeDone = ({record}) => {
        const [doneLoading, setDoneLoading] = useState(false);
        return (
            <Button
                loading={doneLoading}
                onClick={(e) => {
                    e.stopPropagation();
                    setDoneLoading(true);
                    onDoneClick(record, setDoneLoading);
                }}
                type="link">
                {record.IsDone ? "Not Done" : "Done"}
            </Button>
        );
    };

    function loadItemToEdit(item) {
        dispatch(openEdit({item, type: SidePanelItemType.Notes}));
    }

    return (
        <TableWithGroups
            enableInfinityScrolling={false}
            loading={loading}
            items={items}
            columns={columns}
            FilterBar={FilterBar}
            GroupTable={GroupNotesTable}
            loadItemToEdit={loadItemToEdit}
            actionsColumnWidth="90px"
            actions={[MakeDone]}></TableWithGroups>
    );
};

const getLinkedReport = (linkedReport = {}, dateFormat) =>
    [linkedReport.ReportName, linkedReport.ReportDate ? `(${moment(linkedReport.ReportDate).format(dateFormat)})` : ""].join(" ");

export default Notes;
