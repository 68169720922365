import {createSlice} from "@reduxjs/toolkit";

const infrastructureSlice = createSlice({
    name: "infrastructure",
    initialState: {
        timeZones: [],
        timeZoneMappings: {}
    },
    reducers: {
        setTimeZones(state, action) {
            state.timeZones = action.payload;
        },
        setTimeZoneMappings(state, action) {
            state.timeZoneMappings = action.payload;
        },
    }
});

export const {setTimeZones, setTimeZoneMappings} = infrastructureSlice.actions;

export default infrastructureSlice.reducer;
