import React from "react";
import _ from "lodash";
import {Tag, message} from "antd";
import FilterBar from "./FilterBar/FilterBar";
import {startLoading, endLoading} from "../../redux/reducers/detailsPanel";
import {useDispatch} from "react-redux";
import GroupAttachmentsTable from "./GroupAttachmentsTable";
import {SidePanelItemType} from "../../constants/constants";
import {getItem} from "../../services/attachments";
import ProjectsTag from "../Common/Projects/ProjectsTag";
import TableWithGroups from "../Common/Tables/TableWithGroups";
import EditAction from "../Common/Tables/Actions/EditAction";

const allProjectsTag = <Tag>All Projects</Tag>;

const defaultColumns = {
    name: {
        title: "Attachment",
        dataIndex: "Name",
        width: "20%",
        key: "Id",
    },
    categories: {
        title: "Categories",
        dataIndex: "Categories",
        width: "15%",
        render: (items) => (items || []).map((x) => x.Name).join(", "),
    },
    departments: {
        title: "Departments",
        dataIndex: "Departments",
        width: "15%",
        render: (items) => (items || []).map((x) => x.Name).join(", "),
    },

    project: {
        className: "project-column",
        title: "Projects",
        dataIndex: "Projects",
        width: "50%",
    },
};

const AttachmentsTable = ({items = [], type, loading}) => {
    const dispatch = useDispatch();

    function loadItemToEdit(item) {
        dispatch(startLoading({item, type: SidePanelItemType.Attachment}));
        getItem(item.Id)
            .then((item) => dispatch(endLoading({item})))
            .catch(() => message.error("Server error"));
    }

    const columns = [
        defaultColumns.name,
        {
            ...defaultColumns.project,
            render: (projects, {IsAllProjects}) =>
                IsAllProjects ? allProjectsTag : <ProjectsTag projects={projects || []}></ProjectsTag>,
        },
        defaultColumns.categories,
        defaultColumns.departments,
    ];

    const EditAttach = (props) => <EditAction onClick={loadItemToEdit} {...props} />;

    return (
        <TableWithGroups
            items={items}
            filterBarProps={{type}}
            columns={columns}
            FilterBar={FilterBar}
            GroupTable={GroupAttachmentsTable}
            loadItemToEdit={loadItemToEdit}
            actions={[EditAttach]}></TableWithGroups>
    );
};

export default AttachmentsTable;
