import React, {useState, useEffect} from "react";
import {Space, Button} from "antd";
import update from "immutability-helper";
import _ from "lodash";
import {useSelector} from "react-redux";
import "./FilterBar.scss";
import {SidePanelItemType, ViewMode} from "../../../../constants";

const Reset = ({isActive = false, onReset, resetText = "Reset Filters"}) => (
    <Button disabled={!isActive} type="link" onClick={onReset} className="btn-reset">
        {resetText}
    </Button>);

const FilterBar = ({
                       items,
                       applyFilters,
                       applyGroupBy,
                       filterComponents = [],
                       additionalFilters = [],
                       GroupByComponent,
                       resetText,
                       ColumnsVisibilityComponent,
                       ...props
                   }) => {
    const [isFiltered, setIsFiltered] = useState(false);
    const [needResetFilters, setNeedResetFilters] = useState();
    const [filters, setFilters] = useState({});
    const [groupBy, setGroupBy] = useState();
    const currentView = useSelector((state) => state.timeline.currentView);
    const {type} = props;

    useEffect(() => {
        if (applyGroupBy) applyGroupBy(groupBy);
    }, [groupBy, applyGroupBy]);

    const additionalFiltersHash = additionalFilters.map((f) => f.key).join(", ");

    useEffect(() => {
        if (items.length === 0) {
            applyFilters([]);
        }

        const filterHandlers = [..._.values(filters), ...additionalFilters.map((f) => f.handler)];

        if (filterHandlers.length === 0) {
            applyFilters(items);
        } else {
            const afterFilter = filterHandlers.reduce((result, handler) => {
                return result.filter(handler);
            }, items);

            applyFilters(afterFilter);
            setIsFiltered(true);
        }
    }, [items, filters, additionalFiltersHash]);

    function onReset() {
        setNeedResetFilters(true);
        setFilters({});
        setIsFiltered(false);
    }

    useEffect(() => {
        if (needResetFilters) setNeedResetFilters(false);
    }, [needResetFilters]);

    function addFilterHandler(filterKey, filterFunc) {
        setFilters((prev) => update(prev, {[filterKey]: {$set: filterFunc}}));
    }

    return (<div className="filter-bar">
        <Space size={1}>
            {filterComponents.map((FilterComponent, index) => (<FilterComponent
                key={index}
                setFilterHandler={(handler) => addFilterHandler(index, handler)}
                data={items}
                needResetFilter={needResetFilters}
            />))}

            <Reset isActive={isFiltered} onReset={onReset} resetText={resetText}/>
        </Space>

        {type === SidePanelItemType.Event && (currentView === ViewMode.Month || currentView === ViewMode.Day || currentView === ViewMode.Week) ? null : (<>
                {ColumnsVisibilityComponent && (<div className="columns-visibility">
                        <ColumnsVisibilityComponent/>
                    </div>)}

                {GroupByComponent && (<div className="group-by-filter">
                        <GroupByComponent setGroupBy={setGroupBy} {...props} />
                    </div>)}
            </>)}

    </div>);
};

export default FilterBar;